<!-- 用户编辑弹窗 -->
<template>
  <a-modal
    :width="580"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改信息' : '新建信息'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="20" :sm="24" :xs="24">
          <a-form-item label="名称:" name="name">
            <a-input
              allow-clear
              placeholder="请输入名称"
              v-model:value="form.name"
              :disabled="form.userId > 0"
            />
          </a-form-item>
          <a-form-item label="身份证号码:" name="idcard">
            <a-input
              allow-clear
              :maxlength="18"
              placeholder="请输入身份证号码"
              v-model:value="form.idcard"
              :disabled="form.userId > 0"
            />
          </a-form-item>
          <a-form-item label="手机号码:" name="phone">
            <a-input
              allow-clear
              :maxlength="18"
              placeholder="请输入手机号码"
              v-model:value="form.phone"
              :disabled="form.userId > 0"
            />
          </a-form-item>
          <a-form-item label="照片:" name="pic">
            <a-upload
              list-type="picture-card"
              v-model:file-list="fileList"
              @preview="handlePreview"
              :customRequest="uploadFile"
              :remove="removeFile"
            >
              <div v-if="fileList.length === 0">
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">照片</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
          <a-form-item label="地区:" name="area">
            <a-select
              allow-clear
              placeholder="请选择地区"
              v-model:value="form.area"
            >
              <a-select-option
                v-for="item in dataXMRole"
                :key="item.dictDataName"
                :value="item.dictDataName"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="等级:" name="grade">
            <a-select
              allow-clear
              placeholder="请选择等级"
              v-model:value="form.grade"
            >
              <a-select-option value="G">国家级</a-select-option>
              <a-select-option value="F">省级</a-select-option>
              <a-select-option value="X">市级</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="英雄卡号:" name="heroNumber">
            <a-input
              allow-clear
              :maxlength="18"
              placeholder="请输入英雄卡号"
              v-model:value="form.heroNumber"
            />
          </a-form-item>
          <a-form-item label="英雄日期:" name="heroDate">
            <a-date-picker
              class="ud-fluid"
              value-format="YYYY-MM-DD"
              placeholder="请选择英雄日期"
              v-model:value="form.heroDate"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import setting from '@/config/setting'
import * as heroInfoApi from '@/api/jyyw/heroInfo'
import * as dictDataApi from '@/api/sys/dictData'
// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })
// }
export default {
  name: 'navigationEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      selectOption: [
        {
          value: 0,
          label: '不显示'
        },
        {
          value: 1,
          label: '显示'
        }
      ],
      // 表单数据
      form: Object.assign({}, this.data),
      imgList: [],
      heros: [
        {
          name: '',
          idcard: ''
        }
      ],
      uploadUrl: setting.uploadUrl,
      // 表单验证规则
      rules: {},
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      previewVisible: false,
      previewImage: '',
      dataRole: [],
      fileList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
        this.getPic()
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    this.queryDataRoles()
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (this.form.pic) {
            this.form.picPath = 'true'
          }
          heroInfoApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                console.log(res.code)
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    removeFile(file) {
      this.form.pic = ''
    },
    uploadFile({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      heroInfoApi
        .pic(this.form.id || 0, formData)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.form.pic = res.data
            this.fileList = [
              {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: this.form.pic
              }
            ]
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    handlePreview(file) {
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    // async handlePreview(file) {
    //   if (!file.url && !file.preview) {
    //     file.preview = await getBase64(file.originFileObj)
    //   }
    //   this.previewImage = file.url || file.preview
    //   this.previewVisible = true
    // },
    selectChange(value) {
      // console.log(value)
      this.form.isShow = value
    },
    // 地区字典项
    queryDataRoles() {
      dictDataApi
        .querydictdata('dataXMRole')
        .then((res) => {
          if (res.code === 0) {
            this.dataXMRole = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    getPic() {
      const hide = this.$message.loading('加载..', 0)
      heroInfoApi
        .pic(this.form.id || 0)
        .then((res) => {
          if (res.code === 0) {
            this.form.pic = res.data
            if (this.form.pic) {
              this.fileList = [
                {
                  uid: '-1',
                  name: 'image.png',
                  status: 'done',
                  url: this.form.pic
                }
              ]
            } else {
              this.fileList = []
            }
          } else {
            this.$message.error(res.msg)
          }
          hide()
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style scoped>
</style>
